import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import {post} from '../../helpers/api_helper'  

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';

import useDefaultPropsWarningSuppression from '../../useDefaultPropsWarningSuppression';

const UserProfile = (props) => {

  useDefaultPropsWarningSuppression();

  //meta title
  document.title = props.t("Profile")+" | RIDDER TITAN.";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState(1);
  const [userdata, setUserdata] = useState([]);

  const ProfileProperties = createSelector(
    (state) => state.Profile,
    (profile) => ({
      error: profile.error,
      success: profile.success,
    })
  );

  const {
    error,
    success
  } = useSelector(ProfileProperties);

  useEffect(() => {
    const userProfile = async () => {
      try {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const response = await post("/user/profile", {token: obj.token});
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if (response.data.code !== 1) {
          Notify.failure(props.t(response.data.message)); 
        } else {
          let userdata = response.data.data;
              
          setname(userdata.username);
          setemail(userdata.email); 
          if(userdata.set_withdraw_password == true){
            setTradePassword()
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    userProfile(); // Call the async function immediately
  }, []); // Empty dependency array to run the effect only once
 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: { 
      email: email || '' 
    },
    validationSchema: Yup.object({ 
      email: Yup.string().required(props.t("Please Enter Your Email")),
    }),
    onSubmit: async (values) => { 
      try { 
        const obj = JSON.parse(localStorage.getItem("authUser"));
        values.token = obj.token; 
        const response = await post("/user/profileUpdate", values); 
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if(response.data.code !== 1){ 
          Notify.failure(props.t(response.data.message)); 
        } else { 
          if(response.data.data?.result){
            Notify.success(props.t(response.data.data.result)); 
          } else { 
            Notify.success(props.t(response.data.message)); 
          } 

          userProfile()
        }
      } catch (error) {  
        console.log(error)
        // Notify.error(error.data.message); 
      }
    }
  });

  const userProfile = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/user/profile", {token: obj.token});

      if (response.data.code === 3002) {
        // navigate('/logout'); 
      } 

      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        let userdata = response.data.data;
        localStorage.setItem("authUser", JSON.stringify(userdata));
   
        setUserdata(userdata); 
      }
    } catch (error) {
      console.log(error);
    }
  };
 
  const [formTitle, setFormTitle] = useState("");
  const [formType, setFormType] = useState("");
  const [isChangePassword, setIsChangePassword] = useState(false);

  const changePassword = () => {
    setFormTitle(props.t("Change Password"));
    setFormType("changePassword");
    setIsChangePassword(true);
  }

  const changeTradePassword = () => {
    setFormTitle(props.t("Change Trading Password"));
    setFormType("changeTradePassword");
    setIsChangePassword(true);
  }

  const setTradePassword = () => {
    setFormTitle(props.t("Set Trading Password"));
    setFormType("setTradePassword");
    setIsChangePassword(true);
  }
  
  const validationPassword = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      formType: formType,
      current_password: '',
      password: '',
      password_confirm: '' 
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().when('formType', {
        is: (formType) => formType !== 'setTradePassword',
        then:  () => Yup.string().required(props.t("Please Enter Password")),
        otherwise: () =>  Yup.string(),
      }),
      password_confirm:  Yup
      .string()
      .oneOf([Yup.ref('password')], props.t("Passwords must match")),

      current_password: Yup.string().when('formType', {
        is: (formType) => formType !== 'setTradePassword',
        then:  () => Yup.string().required(props.t("Please Enter Current Password")),
        otherwise: () =>  Yup.string(),
      }),
    }),
    onSubmit: async (values) => { 
      try { 
        values.formType = formType; 
        const obj = JSON.parse(localStorage.getItem("authUser"));
        values.token = obj.token; 
        const response = await post("/user/passwordUpdate", values); 
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if(response.data.code !== 1){ 
          Notify.failure(props.t(response.data.message)); 
        } else {
          
          if(response.data.data?.result){
            Notify.success(props.t(response.data.data.result)); 
          } else { 
            Notify.success(props.t(response.data.message)); 
          } 

          userProfile()
 
          setFormTitle("");
          setFormType("");
          setIsChangePassword(false);
        }
      } catch (error) {  
        console.log(error)
        // Notify.error(error.data.message); 
      }
    }
  });

  let enableBtn = validationPassword.isValid &&
  (formType !== 'setTradePassword'
    ? !!validationPassword.values.password && 
      !!validationPassword.values.password_confirm && 
      !!validationPassword.values.current_password
    : !!validationPassword.values.password && 
      !!validationPassword.values.password_confirm);
 

  
  // const [password, setPassword] = useState("");
  // const [password_confirm, setPassword_confirm] = useState("");
  // const [current_password, setCurrent_password] = useState("");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title={props.t("Update Profile")} breadcrumbItem={props.t("Profile")} /> */}

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                        <h5 className="mb-0 card-title flex-grow-1">{props.t("Profile")}</h5> 
                    </div>
                </CardBody>  
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p> 
                      </div>
                    </div>
                  </div>
                    <div className="mt-3">
                      <button onClick={changePassword} className="btn btn-primary btn-sm">{props.t("Change Password")}</button>
                      <button onClick={changeTradePassword} className="btn btn-primary btn-sm ml-1">{props.t("Change Trading Password")}</button>
                    </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
 
          <Card>
            <CardBody className="border-bottom">
                <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">{props.t("Update Profile")}</h5> 
                </div>
            </CardBody>  
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">{props.t("Username")}</Label>
                  <input 
                    className="form-control" value={name} disabled /> 
                </div>
                 
                <div className="form-group mt-3">
                  <Label className="form-label">{props.t("Email")}</Label>
                  <Input
                    name="email" 
                    className="form-control"
                    placeholder={props.t("Enter Email")}
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email ? true : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                  ) : null} 
                </div>

                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    {props.t("Update")}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
 
      <Modal
        isOpen={isChangePassword}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal" 
      >
        <div>
          <ModalHeader className="border-bottom-0" toggle={() => { setIsChangePassword(!isChangePassword); }}></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="mb-4"> 

            <div className="row justify-content-center">
              <div className="col-xl-10">  
                <h4 className="text-primary text-left mb-3">{formTitle}</h4>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validationPassword.handleSubmit();
                  return false;
                }}
              >
                {error ? <Alert color="danger">{error}</Alert> : null}

                <Input
                  name="formType"
                  type="hidden" 
                  value={validationPassword.values.formType} 
                />
                {formType !== "setTradePassword" && ( 
                  <div className="mb-3">
                      <Label className="form-label">{props.t("Current Password")}</Label>
                      <Input
                        name="current_password"
                        type="password"
                        placeholder={props.t("Enter Current Password")}
                        onChange={validationPassword.handleChange}
                        onBlur={validationPassword.handleBlur}
                        value={validationPassword.values.current_password || ""}
                        invalid={
                          validationPassword.touched.current_password && validationPassword.errors.current_password ? true : false
                        }
                      />
                      {validationPassword.touched.current_password && validationPassword.errors.current_password ? (
                        <FormFeedback type="invalid">{validationPassword.errors.current_password}</FormFeedback>
                      ) : null}
                    </div>
                  )}
                <div className="mb-3">
                          <Label className="form-label">{props.t("Password")}</Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder={props.t("Enter Password")}
                            onChange={validationPassword.handleChange}
                            onBlur={validationPassword.handleBlur}
                            value={validationPassword.values.password || ""}
                            invalid={
                              validationPassword.touched.password && validationPassword.errors.password ? true : false
                            }
                          />
                          {validationPassword.touched.password && validationPassword.errors.password ? (
                            <FormFeedback type="invalid">{validationPassword.errors.password}</FormFeedback>
                          ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">{props.t("Password Confirmation")}</Label>
                  <Input
                    name="password_confirm"
                    type="password"
                    placeholder={props.t("Enter Password Confirmation")}
                    onChange={validationPassword.handleChange}
                    onBlur={validationPassword.handleBlur}
                    value={validationPassword.values.password_confirm || ""}
                    invalid={
                      validationPassword.touched.password_confirm && validationPassword.errors.password_confirm ? true : false
                    }
                  />
                  {validationPassword.touched.password_confirm && validationPassword.errors.password_confirm ? (
                    <FormFeedback type="invalid">{validationPassword.errors.password_confirm}</FormFeedback>
                  ) : null}
                </div>
                <div className="mt-4">
                  <button
                    className="btn btn-primary btn-block "
                    type="submit"
                    disabled={!enableBtn}
                  >
                    {props.t("Submit")}
                  </button>
                </div>
                </Form>


              </div>
            </div>
          </div>
        </div> 
      </Modal>



    </React.Fragment>
  );
};
 
export default withTranslation()(withRouter(UserProfile));

UserProfile.propTypes = {
  t: PropTypes.any
};

